<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(import_dialog)}">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol class="mb-2 text-end">
            <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
            <MDBBtn color="success" @click="import_dialog = true">{{ $t("COMMODITY-LIST.IMPORT-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <!-- 操作template -->
          <template v-slot:file="{ item }">
            <a :href="this.url+'/'+ item.file" target="_blank">
              <MDBIcon icon="download" iconStyle="fas" class="text-primary"/>
            </a>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
  <MemberImportForm v-model:model="import_dialog"></MemberImportForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import MemberImportForm from '@/components/Form/Member/MemberImportForm';
export default {
  name: "Import",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MemberImportForm,
  },
  setup() {
    const i18n = useI18n();
    const import_dialog = ref(false);
    const loading = ref(true);
    const desserts = ref([]);
    const total = ref(0);
    const Common = CommonService;
    const url = process.env.VUE_APP_API_URL;
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ["desc"],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",width:"50px",class: "text-center"
      },
      { text: "檔案名稱", value: "name" },
      { text: "匯入時間", value: "created_at" },
      { text: "檔案下載", value: "file", sortable: false,width:"150px",class: "text-center"},
    ]);
		return {
      loading,
      import_dialog,
      options,
      headers,
      desserts,
      total,
      Common,
      url,
    };
  },
  mounted() {
    this.GetTable();
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
    }
  },
  methods: {
		GetTable() {
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query(
          "/Admin/v1/MemberImport",
          {
            params: Object.assign(CommonService.SortHandleObject(this.options)) 
          }
        ).then(response => {
          if (response.status == 200) {
            this.desserts = CommonService.DataNoHandle(
              response.data.data.data,
              this.options.page,
              this.options.itemsPerPage
            );
            this.total = response.data.data.total;
            resolve(response);
          }
          this.loading = false;
        });
      });
    },
  }
};
</script>